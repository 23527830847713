
import { Component, Vue, } from 'vue-property-decorator'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { ControListInterface, } from '@/store/types/Analysis/ControlList'
import { DataroomTemplate, } from '@/store/types/Dataroom/DataroomTemplate'
import { FileUploadInterface, FileUploadApi, } from '@/store/modules/dataroom/dataroomModule'
import { TemplateRecomendation, } from '@/store/types/Dataroom/Templates'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'
import JustificationDialog from '@/components/JustificationDialog/Index.vue'
import ApplyDialog from '@/components/ApplyDialog/Index.vue'
import {
  EditGapInterface,
  SupportsAttributesInterface,
  QuestionInterface,
} from '@/store/types/Analysis/EditGap'

import NavigationGapComponent from '@/components/NavigationGap/Index.vue'
import VueEditor from '@/components/VueEditor/Index.vue'
import LongDescriptionHandlerComponent from '@/components/LongDescriptionHandler/Index.vue'
import { mapGetters, } from 'vuex'
import ModalActivityComponent from "@/components/ModalActivity/Index.vue";

@Component({
  components: {
    NavigationGapComponent,
    VueEditor,
    ConfirmDialog,
    LongDescriptionHandlerComponent,
    JustificationDialog,
    ApplyDialog,OverlayBackground,
    ModalActivityComponent
  },
  computed: {
    ...mapGetters('ActivitiesModule', { selectedActivityArray: 'getSelectedActivityArray', }),
  },
})
export default class DetailControlComponent extends Vue {
  public selectedActivity: any
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private detailSelected = this.$store.state.GapModule.GapState.detailSelected
  private gapControlList = this.$store.state.GapModule.GapState.controlList.gap_analysis_controls
  private questionData: QuestionInterface = {
    name: '',
    code: '',
    description: '',
    question: '',
    subcategory: '',
  }
  private loading = false;

  private controlListInterfaceItem: ControListInterface = {
    id: undefined,
    control_id: '',
    question: '',
    status: '',
    updated_at: '',
    code: '',
    name: '',
  }

  private status = ''
  private criticaly = ''
  private apply = false
  private description = ''
  private detailId: number | undefined = 0
  private finalList = false
  private newindex = 0
  private nameSelected = ''
  private supportAttributes: SupportsAttributesInterface[] = []
  private selectedEvidence = false
  private selectedRecomendation = false
  private selectedJustification = false
  private templateIndex = 0

  // Evidence
  private datarooms: FileUploadApi[] = []
  private dataroomsName: FileUploadApi[] = []
  private deleteRoom: any[] = []
  private nameDatarooms: DataroomTemplate[] = []
  private fileToUpload: FileUploadInterface[] = []
  private files = []
  private titleEvidence = ''
  private contentEvidence = ''
  private saveAsEvidence = false
  private idEvidence = 0

  // Recomendation
  private datarooms1: FileUploadApi[] = []
  private dataroomsName1: FileUploadApi[] = []
  private deleteRoom1: any[] = []
  private nameDatarooms1: DataroomTemplate[] = []
  private fileToUpload1: FileUploadInterface[] = []
  private files1 = []
  private titleRecomendation = ''
  private contentRecomendation = ''
  private saveAsRecomendation = false
  private idRecomendation = 0

  // Justification
  private datarooms2: FileUploadApi[] = []
  private dataroomsName2: FileUploadApi[] = []
  private deleteRoom2: any[] = []
  private nameDatarooms2: DataroomTemplate[] = []
  private fileToUpload2: FileUploadInterface[] = []
  private files2 = []
  private titleJustification = ''
  private contentJustification = ''
  private saveAsJustification = false
  private idJustification = 0

  private alertComparation: string[] = []
  private changeComparation: string[] = []

  private stringComparation = ''
  private changeStringComparation = ''
  private showConfirmModal = false
  private showJustificationModal = false
  private showApplyModal = false

  private functionExecute = 0
  private showMore = false
  private showLess = false

  private evidences: any[] = []

  private redirect = 'false'

  mounted () {
    this.detailId = this.detailSelected.id
    this.GetGapAnalysisControl()
    this.$store.dispatch('RecomendationModule/getTemplatesSmall')

    this.redirect = this.$route.query.redirect ? this.$route.query.redirect.toString() : 'false'
  }

  private goBackDocEve () {
    this.$router.go(-1)
  }

  private goToEvidence (value: any) {
    if (value.is_evidence) {
      this.$router.push({
        name: 'maintenanceDetailClient',
        params: { id: this.$route.params.id, activity_id: value.id, },
      })
    } else {
      this.$router.push({
        name: 'activityDetail',
        params: { id: this.$route.params.id, task_id: value.id, },
      })
    }
  }

  private validateChangeApply () {
    if (this.apply) {
      this.showJustificationModal = false
      this.showApplyModal = true
    } else {
      this.showApplyModal = false
      this.showJustificationModal = true
    }
  }

  private closeApplyDialog (){
    if(this.apply) {
      this.apply = false
      this.showApplyModal = false
    }
  }

  private closeJustificationDialog (){
    if(!this.apply) {
      this.apply = true
      this.showJustificationModal = false
    }
  }

  private donwloadFile (url: string) {
    window.open(url, '_blank')
  }

  private unSaveChanges (value: number, template: ControListInterface) {
    this.changeComparation = []
    this.changeStringComparation = ''

    this.changeComparation.push(this.titleEvidence)
    this.changeComparation.push(this.contentEvidence)
    this.changeComparation.push(this.titleRecomendation)
    this.changeComparation.push(this.contentRecomendation)
    this.changeComparation.push(this.titleJustification)
    this.changeComparation.push(this.contentJustification)

    this.changeStringComparation = JSON.stringify(this.changeComparation)

    if (!this.showConfirmModal) {
      if (this.changeStringComparation !== this.stringComparation) {
        this.controlListInterfaceItem = template
        this.showConfirmModal = true
        return
      }
    }

    switch (value) {
    // Next control
    case 0:
      this.showConfirmModal = false
      this.functionExecute = 0
      this.nextControlFront(
        this.controlListInterfaceItem.id ? this.controlListInterfaceItem : template
      )
      break
      // Back Control
    case 1:
      this.showConfirmModal = false
      this.functionExecute = 1
      this.nextControlBack(
        this.controlListInterfaceItem.id ? this.controlListInterfaceItem : template
      )
      break
      // Back Categories
    case 2:
      this.showConfirmModal = false
      this.functionExecute = 2
      this.controlChange(
        this.controlListInterfaceItem.id ? this.controlListInterfaceItem : template
      )
      break

    default:
      break
    }
  }

  private emptyFields () {
    this.resetVars()
  }

  private resetVars () {
    // Evidence
    this.titleEvidence = ''
    this.contentEvidence = ''
    this.saveAsEvidence = false
    this.datarooms = []
    this.dataroomsName = []
    this.deleteRoom = []
    this.nameDatarooms = []
    this.fileToUpload = []
    this.files = []

    // Recomendation
    this.titleRecomendation = ''
    this.contentRecomendation = ''
    this.saveAsRecomendation = false
    this.datarooms1 = []
    this.dataroomsName1 = []
    this.deleteRoom1 = []
    this.nameDatarooms1 = []
    this.fileToUpload1 = []
    this.files1 = []

    // Justification
    this.titleJustification = ''
    this.contentJustification = ''
    this.saveAsJustification = false
    this.datarooms2 = []
    this.dataroomsName2 = []
    this.deleteRoom2 = []
    this.nameDatarooms2 = []
    this.fileToUpload2 = []
    this.files2 = []

    // Selects
    this.criticaly = ''
    this.status = ''
  }

  private resetIds () {
    this.idEvidence = 0
    this.idRecomendation = 0
    this.idJustification = 0
  }

  private goBack () {
    this.$emit('changeView', 0)
  }

  private resetStatus () {
    this.apply = false
  }

  // Evidence
  private assignedSaveAsEvidence (element: boolean) {
    this.saveAsEvidence = element
  }
  private assignedTitleEvidence (element: string) {
    this.titleEvidence = element
  }
  private assignedContentEvidence (element: string) {
    this.contentEvidence = element
  }

  // Recomendation
  private assignedSaveAsRecomendation (element: boolean) {
    this.saveAsRecomendation = element
  }
  private assignedTitleRecomendation (element: string) {
    this.titleRecomendation = element
  }
  private assignedContentRecomendation (element: string) {
    this.contentRecomendation = element
  }

  // Justification
  private assignedSaveAsJustification (element: boolean) {
    this.saveAsJustification = element
  }
  private assignedTitleJustification (element: string) {
    this.titleJustification = element
  }
  private assignedContentJustification (element: string) {    
    this.contentJustification = element
    this.showJustificationModal = false
    this.saveGapAnalysis()
  }

  private toDataroomEvidence (template: TemplateRecomendation) {
    this.selectedEvidence = true
    template.datarooms.forEach((element) => {
      this.fileToUpload.push({
        filename: element.name,
        url: element.url ? element.url : 'undefined',
      })
    })
    this.nameDatarooms = template.datarooms
  }

  private toDataroomRecomendation (template: TemplateRecomendation) {
    this.selectedRecomendation = true
    template.datarooms.forEach((element) => {
      this.fileToUpload1.push({
        filename: element.name,
        url: element.url ? element.url : 'undefined',
      })
    })
    this.nameDatarooms1 = template.datarooms
  }

  private toDataroomJustification (template: TemplateRecomendation) {
    this.selectedJustification = true
    template.datarooms.forEach((element) => {
      this.fileToUpload2.push({
        filename: element.name,
        url: element.url ? element.url : 'undefined',
      })
    })
    this.nameDatarooms2 = template.datarooms
  }

  private saveGapAnalysis () {
    // if (this.apply === '1') {
    //   if (this.status === '' || this.criticaly === '') {
    //     this.$store.dispatch('NotificactionsModule/OpenNotification', {
    //       message: 'Error, Faltan campos por llenar',
    //       color_notification: 'danger',
    //     })
    //     return
    //   }
    // }

    this.showApplyModal = false

    this.changeComparation.push(this.titleEvidence)
    this.changeComparation.push(this.contentEvidence)
    this.changeComparation.push(this.titleRecomendation)
    this.changeComparation.push(this.contentRecomendation)
    this.changeComparation.push(this.titleJustification)
    this.changeComparation.push(this.contentJustification)

    this.changeStringComparation = JSON.stringify(this.changeComparation)

    this.supportAttributes = []

    this.loading = true

    if (this.selectedEvidence && this.contentEvidence === '' && this.titleEvidence === '') {
      this.deleteRoom = []
    }
    if (
      this.selectedRecomendation &&
      this.contentRecomendation === '' &&
      this.titleRecomendation === ''
    ) {
      this.deleteRoom1 = []
    }
    if (
      this.selectedJustification &&
      this.contentJustification === '' &&
      this.titleJustification === ''
    ) {
      this.deleteRoom2 = []
    }

    // const evidenceData: SupportsAttributesInterface = {
    //   ...(this.idEvidence && {
    //     id: this.idEvidence !== 0 ? this.idEvidence : undefined,
    //   }),
    //   title: this.titleEvidence,
    //   description: this.contentEvidence,
    //   support_type: 'Evidence',
    //   save_as_template: this.saveAsEvidence,
    //   datarooms_data: this.fileToUpload,
    //   datarooms_delete_ids: this.deleteRoom,
    //   ...(this.idEvidence !== 0 &&
    //     this.titleEvidence === '' && {
    //     _destroy: true,
    //   }),
    // }

    // const recomendationData: SupportsAttributesInterface = {
    //   ...(this.idRecomendation && {
    //     id: this.idRecomendation !== 0 ? this.idRecomendation : undefined,
    //   }),
    //   title: this.titleRecomendation,
    //   description: this.contentRecomendation,
    //   support_type: 'Recommendation',
    //   save_as_template: this.saveAsRecomendation,
    //   datarooms_data: this.fileToUpload1,
    //   datarooms_delete_ids: this.deleteRoom1,
    //   ...(this.idRecomendation !== 0 &&
    //     this.titleRecomendation === '' && {
    //     _destroy: true,
    //   }),
    // }

    const justifyData: SupportsAttributesInterface = {
      ...(this.idJustification && {
        id: this.idJustification !== 0 ? this.idJustification : undefined,
      }),
      title: this.titleJustification,
      description: this.contentJustification,
      support_type: 'Justification',
      save_as_template: this.saveAsJustification,
      datarooms_data: this.fileToUpload2,
      datarooms_delete_ids: this.deleteRoom2,
      ...(this.idJustification !== 0 &&
        this.titleJustification === '' && {
        _destroy: true,
      }),
    }

    if (this.contentJustification) {
      this.supportAttributes.push(justifyData)
    } else if (this.idJustification !== 0) {
      this.supportAttributes.push(justifyData)
    }

    let applyData = true
    let statusData = this.status
    let criticalityData = this.criticaly

    if (!this.apply) {
      applyData = false
      statusData = 'inapplicable'
      criticalityData = 'low'
    }

    const editGap: EditGapInterface = {
      apply: applyData,
      status: statusData,
      criticality: criticalityData,
      supports_attributes: this.supportAttributes,
    }

    this.$store
      .dispatch('GapModule/editGap', editGap)
      .then(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Cambios guardados',
        })
        this.$mixpanel.track('Evidencia guardada - control', {
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
          Control: this.$store.state.GapModule.GapState.detailSelected.name,
        })
        this.resetVars()
        this.resetIds()
        this.resetStatus()
        this.GetGapAnalysisControl()
        this.$emit('close')
      })
      .catch(() => {
        this.loading = false
        this.$emit('close')
      })
  }

  private changeView (element: number) {
    this.$emit('changeView', element)
  }

  private nextControlBack (template: ControListInterface) {
    this.resetVars()
    this.resetStatus()
    this.resetIds()
    this.templateIndex = this.gapControlList.findIndex(
      (p: ControListInterface) => p.id === template.id
    )

    this.newindex = this.templateIndex - 1
    if (this.newindex === -1) {
      return
    }
    this.detailId = this.gapControlList[this.templateIndex - 1].id
    this.newindex === this.gapControlList.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)

    this.$router.push({
      name: 'editControl',
      params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
    })
    this.GetGapAnalysisControl()
  }

  private nextControlFront (template: ControListInterface) {
    this.resetVars()
    this.resetStatus()
    this.resetIds()
    this.templateIndex = this.gapControlList.findIndex(
      (p: ControListInterface) => p.id === template.id
    )
    this.newindex = this.templateIndex + 1

    if (this.newindex > this.gapControlList.length + 1) {
      return
    }

    this.detailId = this.gapControlList[this.templateIndex + 1].id
    this.newindex === this.gapControlList.length + 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', this.gapControlList[this.templateIndex + 1])
    this.$router.push({
      name: 'editControl',
      params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
    })
    this.GetGapAnalysisControl()
  }

  private controlChange (element: ControListInterface) {
    this.resetVars()
    this.resetStatus()
    this.resetIds()
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', element)
    this.newindex = this.gapControlList.findIndex((p: ControListInterface) => p.id === element.id)

    this.newindex === this.gapControlList.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)

    this.detailId = element.id

    this.$router.push({
      name: 'editControl',
      params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
    })
    this.GetGapAnalysisControl()
  }

  private GetGapAnalysisControl () {
    this.changeComparation = []
    this.changeStringComparation = ''
    this.stringComparation = ''
    this.alertComparation = []

    this.controlListInterfaceItem = {
      id: undefined,
      control_id: '',
      question: '',
      status: '',
      updated_at: '',
      code: '',
      name: '',
    }

    this.$store.dispatch('ActivitiesModule/getActivityByGap', this.detailId)
    this.loading = true
    this.$store
      .dispatch('GapModule/GetGapAnalysisControl', this.detailId)
      .then((data) => {
        this.evidences = data.data.gap_analysis.evidences

        this.apply = data.data.gap_analysis.apply

        this.questionData = data.data.gap_analysis.control

        this.nameSelected = `${data.data.gap_analysis.control.code} ${data.data.gap_analysis.control.name}`

        this.newindex = this.gapControlList.findIndex(
          (p: ControListInterface) => p.id === this.detailId
        )
        this.newindex === this.gapControlList.length - 1
          ? (this.finalList = true)
          : (this.finalList = false)

        data.data.gap_analysis.criticality !== null
          ? (this.criticaly = data.data.gap_analysis.criticality)
          : (this.criticaly = '')

        data.data.gap_analysis.status === 'unrelieved'
          ? (this.status = '')
          : data.data.gap_analysis.status !== null
            ? (this.status = data.data.gap_analysis.status)
            : ''

        if (data.data.gap_analysis.supports.length) {
          // Assignation for Supports Atributters
          data.data.gap_analysis.supports.forEach((element: any) => {
            if (element.support_type === 'evidence') {
              this.titleEvidence = element.title
              this.contentEvidence = element.description
              this.idEvidence = element.id
              this.nameDatarooms = element.files
            }
            if (element.support_type === 'recommendation') {
              this.titleRecomendation = element.title
              this.contentRecomendation = element.description
              this.idRecomendation = element.id
              this.nameDatarooms1 = element.files
            }
            if (element.support_type === 'justification') {
              this.titleJustification = element.title
              this.contentJustification = element.description
              this.idJustification = element.id
              this.nameDatarooms2 = element.files
            }
          })
        }

        this.alertComparation.push(this.titleEvidence)
        this.alertComparation.push(this.contentEvidence)
        this.alertComparation.push(this.titleRecomendation)
        this.alertComparation.push(this.contentRecomendation)
        this.alertComparation.push(this.titleJustification)
        this.alertComparation.push(this.contentJustification)

        this.stringComparation = JSON.stringify(this.alertComparation)

        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  // deleteFileApi
  private deleteFileAPi (index: number) {
    this.deleteRoom.push(this.nameDatarooms[index].id)
    this.nameDatarooms.splice(index, 1)
    this.fileToUpload.splice(index, 1)
  }
  private deleteFileAPi1 (index: number) {
    if (!this.selectedRecomendation) {
      this.deleteRoom1.push(this.nameDatarooms1[index].id)
    }
    this.nameDatarooms1.splice(index, 1)
    this.fileToUpload1.splice(index, 1)
  }
  private deleteFileAPi2 (index: number) {
    this.deleteRoom2.push(this.nameDatarooms2[index].id)
    this.nameDatarooms2.splice(index, 1)
    this.fileToUpload2.splice(index, 1)
  }

  // deleteFileLocal
  private deleteFileLocal (index: number) {
    const refFiles: any = this.$refs.myFiles

    refFiles.type = 'text'
    refFiles.type = 'file'

    this.dataroomsName.splice(index, 1)
    this.fileToUpload.splice(index, 1)
  }
  private deleteFileLocal1 (index: number) {
    const refFiles: any = this.$refs.myFiles1

    refFiles.type = 'text'
    refFiles.type = 'file'

    this.dataroomsName1.splice(index, 1)
    this.fileToUpload1.splice(index, 1)
  }
  private deleteFileLocal2 (index: number) {
    const refFiles: any = this.$refs.myFiles2

    refFiles.type = 'text'
    refFiles.type = 'file'

    this.dataroomsName2.splice(index, 1)
    this.fileToUpload2.splice(index, 1)
  }

  // previewFiles
  private previewFiles () {
    this.readbase64()
  }
  private previewFiles1 () {
    this.readbase641()
  }
  private previewFiles2 () {
    this.readbase642()
  }

  // readbase64
  private readbase64 () {
    if (!this.files) return

    const refFiles: any = this.$refs.myFiles
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      const fileExtension = fileAux.split('.').pop() || ''
      const extensions = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'gif']
      if (extensions.includes(fileExtension)) {
        this.dataroomsName.push({ name: fileAux, })
        const readerResult: string | undefined = reader?.result?.toString()
        this.fileToUpload.push({
          filename: fileAux,
          data: readerResult,
        })
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }
  private readbase641 () {
    if (!this.files1) return

    const refFiles: any = this.$refs.myFiles1
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      const fileExtension = fileAux.split('.').pop() || ''
      const extensions = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'gif']

      if (extensions.includes(fileExtension)) {
        this.dataroomsName1.push({ name: fileAux, })
        const readerResult: string | undefined = reader?.result?.toString()
        this.fileToUpload1.push({
          filename: fileAux,
          data: readerResult,
        })
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }
  private readbase642 () {
    if (!this.files2) return

    const refFiles: any = this.$refs.myFiles2

    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      const fileExtension = fileAux.split('.').pop() || ''
      const extensions = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'gif']

      if (extensions.includes(fileExtension)) {
        this.dataroomsName2.push({ name: fileAux, })
        const readerResult: string | undefined = reader?.result?.toString()
        this.fileToUpload2.push({
          filename: fileAux,
          data: readerResult,
        })
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }

  private destroyed () {
    this.$store.dispatch('PolimorficModule/openModal', false)
  }

  private openModalActivity () {
    this.$store.dispatch('PolimorficModule/openModal', true)
    const currentUserRole = this.$store.state.AuthModule.AuthState.role
    if (currentUserRole == 'implementer') {
      this.$mixpanel.track('Ver Actividad', {
        'Nombre Control': this.$store.state.GapModule.GapState.detailSelected.name,
      })
    }
  }

  get getTypeNav () {
    if (this.$route.name === 'clients') {
      return 0
    } else {
      return 2
    }
  }
}
