
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import ClickOutside from 'vue-click-outside'

import { ControListInterface } from '@/store/types/Analysis/ControlList'

@Component({
  computed: {
    ...mapGetters('GapModule', [
      'getCategorieSelected',
      'getDetailSelected',
      'getDateVersion',
      'getFrameworkSelectedHistory',
    ]),
    ...mapGetters('BusinessModule', ['getBusinessFrameworks']),
    ...mapGetters('PolimorficModule', ['getFrameworkSelected']),
  },
  components: {
    ClickOutside,
  },
  directives: {
    ClickOutside,
  },
})
export default class NavigationGapComponent extends Vue {
  @Prop({ required: false, default: 1 }) readonly typeNav!: number
  @Prop({ required: true, default: false }) readonly finalList!: boolean
  @Prop({ required: true }) readonly newindex!: number
  @Prop({ required: false, default: '' }) readonly nameSelected!: string
  private categories = this.$store.state.GapModule.GapState.categories
  private gapControlList = this.$store.state.GapModule.GapState.controlList.gap_analysis_controls
  private historyControlList = this.$store.state.HistoryModule.HistoryState.historyNav
  private typeUser = this.$store.state.AuthModule.AuthState.role
  private versionApp = this.$store.state.GapModule.GapState.version_categories
  private versionDate = this.$store.state.GapModule.GapState.dateVersion
  private showMenu = false

  private changeMenu() {
    this.showMenu = !this.showMenu
  }

  private changeMenuBefore(element: ControListInterface) {
    if (
      this.$route.name === 'historyStartupControlsList' ||
      this.$route.name === 'controlsList' ||
      this.$route.name === 'historyControlsList' ||
      this.$route.name === 'startupControlsList'
    ) {
      this.showMenu = !this.showMenu
    } else {
      if (this.$route.name === 'detailControl' || this.$route.name === 'editControl') {
        this.goToRoute('controlsList', element.id ? element.id.toString() : 'null')
      } else if (this.$route.name === 'startupDetailControl') {
        this.goToRoute('startupControlsList', element.id ? element.id.toString() : 'null')
      } else if (this.$route.name === 'historyDetailControl') {
        this.goToRoute('historyControlsList', element.id ? element.id.toString() : 'null')
      } else if (this.$route.name === 'historyStartupDetailControl') {
        this.goToRoute('historyStartupControlsList', element.id ? element.id.toString() : 'null')
      }
    }
  }

  private goToRoute(name: string, id: string) {
    this.$router.push({
      name: name,
      params: { id_category: id ? id.toString() : 'null' },
    })
  }

  private goToRouteDashboard(name: string) {
    this.$router.push({
      name: name,
    })
  }

  private goToControl(element: ControListInterface) {
    this.showMenu = false
    this.$emit('controlChange', element)
  }

  private nextControlBack(element: ControListInterface) {
    this.$emit('nextControlBack', element)
    this.$mixpanel.track('Control Anterior')
  }

  private hide() {
    this.showMenu = false
  }

  private nextControlFront(element: ControListInterface) {
    this.$emit('nextControlFront', element)
    this.$mixpanel.track('Control Siguiente')
  }

  private goToDashboard() {
    const routeName = this.$route?.name || ''
    if (['detailControl', 'editControl', 'controlsList'].includes(routeName)) {
      this.goToRouteDashboard('category')
    } else if (['historyDetailControl', 'historyControlsList'].includes(routeName)) {
      this.goToRouteDashboard('DashboardGap')
    } else if (['historyStartupDetailControl', 'historyStartupControlsList'].includes(routeName)) {
      this.goToRouteDashboard('startupDashboardGap')
    } else if (['controlDetail'].includes(routeName)) {
      this.$router.go(-1)
    } else if (['startupControlsList', 'startupDetailControl'].includes(routeName)) {
      const lastFrameworkPath = localStorage.getItem('lastComplianceOpen')
      if (lastFrameworkPath) {
        this.$router.push({
          path: lastFrameworkPath,
        })
      } else {
        this.$router.go(-1)
      }
    }
  }

  get getNavsItems() {
    switch (this.typeNav) {
      case 0:
        return this.categories
      case 1:
        return this.gapControlList
      case 2:
        if (this.$route.query.history || this.$route.name === 'clientHistory')
          return this.historyControlList
        return this.gapControlList
      default:
        return this.categories
    }
  }

  get getNameAction() {
    return 'Volver a la lista de controles'
  }

  get getNameBack() {
    const isControlDetail = this.$route.name !== 'controlDetail'

    return isControlDetail ? 'Volver al analisis gap' : 'Volver al historial de avances'
  }
}
