
import { mapGetters, } from 'vuex'
import { Component, Vue, Watch, } from 'vue-property-decorator'

@Component({
  components: {},
  computed: {
    ...mapGetters('PolimorficModule', ['getStatusModal',]),
    ...mapGetters('StepperDocumentModule', ['getHistoryData',]),
  },
})
export default class ModalActivityComponent extends Vue {
  private open = true
  public getHistory: any
  private typeUser = this.$store.state.AuthModule.AuthState.role
  private userId = this.$route.params.id
  private nameDocument = ''

  private openModalActivity () {
    this.$store.commit('PolimorficModule/SET_STATUS_MODAL', false)
  }

  private goToDataRoom () {
    if (this.typeUser === 'implementer' || this.typeUser === 'customer_success') {
      this.$router.push({ name: 'clientDataroom', params: { id: this.userId, }, })
    } else {
      this.$mixpanel.track('Ir a Dataroom desde Control')
      this.$router.push({ path: '/startup/dataroom', })
    }
    this.$store.commit('PolimorficModule/SET_STATUS_MODAL', false)
  }

  get getIconAction () {
    if (this.open) {
      return 'expand_less'
    } else {
      return 'expand_more'
    }
  }

  @Watch('$route', { immediate: true })
  onRouteChange(to: any, from: any) {
    if(from.fullPath != to.fullPath && this.$store.state.PolimorficModule.PolimorficState.openModalActivity) {
      this.openModalActivity();
    }
  }
}
