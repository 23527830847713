
import { Component, Vue, Prop, } from 'vue-property-decorator'
import * as Validations from '@/utils/Validations'

@Component
export default class LongDescriptionHandlerComponent extends Vue {
  @Prop({ required: false, default: '', }) description!: string
  @Prop({ required: false, default: '', }) elementId!: string
  @Prop({ required: false, default: false, }) chips!: boolean
  @Prop({ required: false, default:100, }) maxHeight!: number
  @Prop({ required: false, default: false, }) resources!: boolean

  private description_button = false

  private showSeeMoreDescription (v: string) {
    if (this.chips) {
      if (v.length > 3 && this.description_button === false) {
        return true
      }
      return false
    } 

    if (this.resources) {
      if (v.length > 4 && this.description_button === false) {
        return true
      }
      return false
    }

    if (v.split(Validations.regexBreakLines).length > 3 && this.description_button === false) {
      return true
    }
    return false
  }
  private fullText (v: string) {
    const a = document.getElementById(v)
    if(a) {
      a.style.maxHeight = 'auto'
      a.style.maxHeight = a.scrollHeight+'px'
      this.description_button = true
    }
  }
  private lessText (v: string) {
    const a = document.getElementById(v)
    if(a) {
      a.style.maxHeight = this.maxHeight + 'px'
      this.description_button = false
    }
  }
}
